import React from 'react';

import { UqCustomerStory, UqTypography, UqLink, UqPage, UqLayoutV2, UqContainerV2, UqAppBarV2, UqNavbarV2, UqFooterV2, UqCustomerProfile, UqArticleQuote, UqRequestDemoBanner } from '@uq-components';
import Logo from '@uq-assets/logos/daily-pay.svg';

import * as styles from './dailypay.module.scss';

export default function DailypayPage() {
  return (
    <UqPage
      config={{
        seo: {
          src: '/images/v2/customers/dailypay/dailypay-hero.png',
          description: `Learn how DailyPay leveraged unitQ and user feedback data to align support,
          product and engineering teams to improve product quality.`,
          title: 'DailyPay Customer Story',
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqCustomerStory>
          <UqCustomerStory.Header
            backgroundSrc="/images/v2/customers/dailypay/dailypay-hero.png"
            classes={{ background: styles.hero }}
          >
            <UqTypography as="h1">
              {'DailyPay + unitQ: <br /> Banking On User Feedback to Align Internal Teams on Product Quality'}
            </UqTypography>
          </UqCustomerStory.Header>

          <UqCustomerStory.Content>
            <UqCustomerProfile logo={<Logo />}>
              <UqCustomerProfile.Section title="Product">Financial Services</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Headquarters">New York, NY</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Employees">500+</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Quality Challenge">
                Missing critical user feedback, disconnects between Support and Products,
                over-reliance on social media quality signals, manual categorization efforts
              </UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Results">
                <ul>
                  <li>A single source of user feedback and product quality truth</li>
                  <li>Comprehensive capture of user feedback from internal and external sources</li>
                  <li>Tighter alignment between Product and Support via quantitative user feedback insights</li>
                  <li>Data-driven decisions bring faster, more focused product quality improvements</li>
                </ul>
              </UqCustomerProfile.Section>
            </UqCustomerProfile>

            <UqCustomerStory.Article>
              <p>
                <UqLink
                  className="article-link"
                  to="https://www.dailypay.com/"
                  hideArrow
                  external
                >
                  DailyPay
                </UqLink>
                {' '}
                <span>
                  is an award-winning technology company that provides access to earned pay and
                  financial solutions to partnering enterprises and Fortune 500 companies to
                  ensure money is always in the right place at the right time for employers,
                  merchants, employees, and financial systems. Featured in
                </span>
                <span className="emphasized">Time Magazine&apos;s </span>
                <span>
                  ,&quot;The Best Inventions in 2021,&quot;
                  DailyPay is on a mission to create a new financial system that works best for everyone.
                </span>
              </p>

              <UqTypography as="h4">
                Product Quality Improvements Hampered by !-Fragmented User Feedback Insights-!
              </UqTypography>
              <p>
                DailyPay knows the satisfaction of the employees using its service is critical to
                maintaining its growth and momentum. But different internal teams captured different
                elements of user feedback from across Twitter, TrustPilot, Apple App Store, Zendesk,
                and more, leaving individual teams with a fragmented view of product quality.
                Lack of deeper insights also prevented DailyPay from differentiating between true
                product quality issues and usability opportunities. And without quantitative user
                feedback metrics, the support and product teams couldn`&apos;t identify which quality issues
                were most critical or how quality-related product enhancements should be prioritized.
              </p>

              <UqArticleQuote
                author={{
                  name: 'Gretchen Scheiman',
                  role: 'VP Brand Marketing, DailyPay',
                }}
              >
                Support gathered feedback in Zendesk, and our social marketing team looked at reviews on
                TrustPilot, BBB, Apple App Store, and Google Play Store through one tool while monitoring
                Twitter and Facebook through another tool. No one team had a complete view of user feedback.
                Or, we were focused on a small sample of user feedback and couldn`&apos;t zoom out to see our top
                product quality priorities.
              </UqArticleQuote>

              <UqTypography as="h4">
                Driving Product Quality With a !-More Efficient User Feedback Loop-!
              </UqTypography>

              <p>
                Consolidating user feedback data from Zendesk and the many external sources was critical,
                but the existing tools weren&apos;t working the way the company needed. Efficiency was key, but
                so was finding better ways to tag and categorize the incoming user feedback for issues like
                understanding account balances, verifying accounts, and paycheck support. DailyPay also wanted
                to quantify the size and scope of quality issues and align the marketing, engineering, support
                and product teams to improve product quality.
              </p>

              <p>
                DailyPay found a solution with unitQ Monitor, an AI-enabled product quality monitoring platform
                which captures user feedback from dozens of internal and external sources, intelligently
                understands the sentiment and categorizes issues, and provides alerts, reports, and integrations
                to internal tools, all without adding headcount. With unitQ Monitor, DailyPay gets a single view
                of product quality feedback, translated into English and pulled from across iOS, Android, web,
                and other user platforms. These near-instant insights improve reaction times on product fixes
                while giving DailyPay a common, data-driven solution for prioritizing product and quality efforts.
              </p>

              <UqArticleQuote
                author={{
                  name: 'Dar Miranda',
                  role: 'VP, Product Management, DailyPay',
                }}
              >
                unitQ captures user feedback data from so many sources, but then also automates the categorizations,
                evaluates sentiment, and gives us real product quality insights without any extra effort.
              </UqArticleQuote>

              <UqTypography as="h4">
                Uniting Support, Product, and Engineering Around !-User Feedback-!
              </UqTypography>

              <p>
                DailyPay wanted to better leverage user feedback to inform product quality decisions and product
                improvement efforts. A fragmented approach made it harder to quantify insights and created
                unnecessary barriers to seeing the whole picture. But unitQ Monitor now provides a single source
                of user feedback, giving support, product, and engineering a clear view into product quality issues
                using data from Zendesk support tickets, social media, app stores, and other external sources.
              </p>

              <p>
                With unitQ Monitor, DailyPay has made it easier for teams across the business to see the whole picture,
                enabling the product team to drive faster, focused product fixes for the issues users care about. Other
                unitQ Monitor benefits for DailyPay include:
              </p>

              <ul>
                <li>
                  Intuitive reports and dashboards that quantify user feedback
                  and quality issues for data-driven decisions.
                </li>
                <li>
                  Comprehensive user feedback from Twitter, Reddit,
                  Zendesk, the Apple App Store, Google Play, and other sources to ensure every user&apos;s voice is heard.
                </li>
                <li>
                  Intelligent user feedback categorizations that help the support team manage
                  incoming issues and monitor and improve the overall support process.
                </li>
                <li>
                  Access to the unitQ Score, a quality metric that gives decision-makers
                  a new KPI to measure and track product health and quality.
                </li>
              </ul>

              <br />
              <span className="emphasized">
                In order to provide long-term scalability and security for customers like DailyPay,
                unitQ’s architecture leverages several AWS services. Amazon Kinesis Data Streams
                serve as a pipeline for the millions of pieces of feedback we process and help
                ensure no data is lost as it winds its way through various redundant microservices
                which run in Amazon Elastic Kubernetes Service (EKS). Amazon OpenSearch Service
                then makes it possible to quickly aggregate millions of data points into our UI,
                allowing customers to create charts and dashboards for easy reporting on their user
                feedback and to share insights across the organization.
              </span>
            </UqCustomerStory.Article>
          </UqCustomerStory.Content>
        </UqCustomerStory>

        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}
